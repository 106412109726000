import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
   appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getFCMToken = async vapidKey => {
   try {
      if (!vapidKey) {
         throw new Error("VAPID key is required");
      }

      const currentToken = await getToken(messaging, {
         vapidKey,
         serviceWorkerRegistration: await navigator.serviceWorker.getRegistration()
      });

      if (currentToken) {
         return currentToken;
      }

      console.log("No registration token available. Request permission to generate one."); // eslint-disable-line no-console
      return null;
   } catch (err) {
      console.error("An error occurred while retrieving token:", err); // eslint-disable-line no-console
      throw err;
   }
};

export { messaging, getFCMToken };

<template>
   <div id="topbar">
      <div class="title" v-if="!role.planner">
         <h1>{{ pageTitle }}</h1>
      </div>

      <!-- [Planner] Client Info -->
      <div v-else-if="role.planner">
         <v-btn
            text
            rounded
            color="primary"
            :disabled="role.planner && inDashboard"
            :to="{ name: 'dashboard' }"
            ><v-icon>mdi-home-outline</v-icon>Dashboard</v-btn
         >
      </div>

      <!-- [Planner] End Client Info -->

      <div class="menuOptions userInfo">
         <!-- Notification -->
         <notification-bell
            class="notification"
            v-if="role.planner && isPermissionInsight"
            style="margin-right: 25px"
         />
         <div class="notification" v-if="role.client">
            <v-menu
               bottom
               nudge-bottom="45"
               :close-on-content-click="true"
               class="notification-menu"
            >
               <template v-slot:activator="{ on }">
                  <div id="clientMenu" v-if="isMobile">
                     <chat-button v-if="isClient && chatUserEnable" />
                  </div>
                  <v-btn fab small text v-on="on" @click="initNotifications">
                     <span class="notificationBaloon" v-if="newTasks.length">{{
                        newTasks.length
                     }}</span>
                     <v-icon color="primary">mdi-bell-outline</v-icon>
                  </v-btn>
               </template>

               <!-- Notification List -->
               <v-list v-if="myTasks.length && !notificationLoader.status" class="notificationList">
                  <v-list-item
                     v-for="(task, index) in myTasks"
                     :class="isNewTask(task) ? 'pending' : 'done'"
                     :key="index"
                     @click="() => gotoTask(task)"
                  >
                     <v-list-item-title>
                        <div class="notificationItem">
                           <!-- Header -->
                           <div class="header">
                              <!-- Menu of Options -->
                              <v-menu bottom nudge-bottom="30">
                                 <template v-slot:activator="{ on }">
                                    <v-btn color="inherit" small icon v-on="on">
                                       <v-icon :size="22">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                 </template>

                                 <v-list>
                                    <v-list-item @click="() => ignoreTask(task)">
                                       <v-list-item-title>Rejeitar tarefa</v-list-item-title>
                                    </v-list-item>
                                 </v-list>
                              </v-menu>
                              <!-- End Menu of Options -->
                           </div>
                           <!-- End Header -->

                           <!-- Notification Content -->
                           <div class="notificationContent">
                              <div class="iconHolder">
                                 <v-icon :size="50">{{ taskIcon(task) }}</v-icon>
                              </div>
                              <div class="notificationBody" v-if="!task.title">
                                 {{ plannerName }} solicita uma nova {{ taskType(task) }}.
                              </div>
                              <div class="notificationBody" v-else>
                                 {{ task.title }}
                              </div>
                           </div>
                           <!-- Notification Content -->
                        </div>
                     </v-list-item-title>
                  </v-list-item>
               </v-list>

               <!-- Loading List -->
               <v-list v-else-if="notificationLoader.status" class="loading">
                  <v-list-item>
                     <div class="loading">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                     </div>
                  </v-list-item>
               </v-list>
               <!-- End Loading List -->

               <!--  -->
               <v-list v-else-if="!myTasks.length && !notificationLoader.status">
                  <v-list-item>
                     <div class="no-content">Não existem importações ainda.</div>
                  </v-list-item>
               </v-list>

               <!-- Notification List -->
            </v-menu>
         </div>
         <!-- End Notification -->

         <!-- Config -->
         <v-menu
            v-model="menuStatus"
            transition="slide-y-transition"
            bottom
            nudge-bottom="40"
            :close-on-content-click="false"
         >
            <template v-slot:activator="{ on }">
               <div class="config d-none d-sm-block" v-on="on">
                  <!-- Menu -->
                  <span class="settings">
                     <span class="avatar mr-2">
                        <UserFigure class="small" :userData="{ avatar, name: userFullName }" />
                     </span>
                     <div class="userName primary--text">
                        <span>{{ userFullName }}</span>
                        <v-icon color="primary">mdi-chevron-down</v-icon>
                     </div>
                  </span>
               </div>
            </template>
            <!-- List Items -->
            <v-list class="accountSettings">
               <v-list-item
                  @click="$router.push({ name: 'perfil', query: { clientId: clientIdQuery } })"
               >
                  <v-list-item-title @click="() => (menuStatus = false)">
                     <v-icon>mdi-account-edit-outline</v-icon>
                     <span class="d-inline-block ml-2">Editar Perfil</span>
                  </v-list-item-title>
               </v-list-item>
               <v-list-item v-if="role.client && persona.has_opb_iniciador">
                  <v-list-item-title @click="openLink" style="cursor: pointer;">
                     <v-icon>mdi-view-dashboard</v-icon>
                     <span class="d-inline-block ml-2">Gestão Open Finance</span>
                  </v-list-item-title>
               </v-list-item>
               <v-list-item
                  @click="
                     $router.push({
                        name: 'perfil',
                        params: { view: 'assinatura' },
                        query: { clientId: clientIdQuery }
                     })
                  "
                  v-if="hasAccessToSignature"
               >
                  <v-list-item-title @click="() => (menuStatus = false)">
                     <v-icon>mdi-credit-card-check-outline</v-icon>
                     <span class="d-inline-block ml-2">Assinatura</span>
                  </v-list-item-title>
               </v-list-item>
               <VersionInfo />

               <v-list-item v-if="isClient" @click="resetTourAndRedirect">
                  <v-list-item-title @click="() => (menuStatus = false)">
                     <v-icon>mdi-restart</v-icon>
                     <span class="d-inline-block ml-2">Resetar Tour</span>
                  </v-list-item-title>
               </v-list-item>

               <v-list-item @click="kickUser" v-if="isEnabledLogout">
                  <v-list-item-title @click="() => (menuStatus = false)">
                     <v-icon>mdi-logout</v-icon>
                     <span class="d-inline-block ml-2">Sair</span>
                  </v-list-item-title>
               </v-list-item>
            </v-list>
         </v-menu>
         <!-- End Config -->
         <v-app-bar flat class="d-sm-none">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
         </v-app-bar>
         <div v-if="drawer">
            <v-navigation-drawer v-model="drawer" absolute right temporary>
               <v-list nav>
                  <v-list-item-group
                     v-model="group"
                     active-class="deep-purple--text text--accent-4"
                  >
                     <v-list-item-avatar width="100" height="100">
                        <UserFigure :userData="{ avatar, name: userFullName }" />
                     </v-list-item-avatar>
                     <v-list-item-content>
                        <v-list-item-title class="text-h6">
                           {{ userFullName }}
                        </v-list-item-title>
                     </v-list-item-content>
                     <v-divider></v-divider>
                     <v-list-item
                        @click="
                           $router.push({ name: 'perfil', query: { clientId: clientIdQuery } })
                        "
                     >
                        <v-list-item-title @click="() => (menuStatus = false)">
                           <v-icon>mdi-account-edit-outline</v-icon>
                           <span class="d-inline-block ml-2">Editar Perfil</span>
                        </v-list-item-title>
                     </v-list-item>
                     <v-list-item v-if="role.client && persona.has_opb_iniciador">
                        <v-list-item-title @click="openLink" style="cursor: pointer;">
                           <v-icon>mdi-view-dashboard</v-icon>
                           <span class="d-inline-block ml-2">Gestão Open Finance</span>
                        </v-list-item-title>
                     </v-list-item>
                     <v-list-item
                        @click="
                           $router.push({
                              name: 'perfil',
                              params: { view: 'assinatura' },
                              query: { clientId: clientIdQuery }
                           })
                        "
                        v-if="hasAccessToSignature"
                     >
                        <v-list-item-title @click="() => (menuStatus = false)">
                           <v-icon>mdi-credit-card-check-outline</v-icon>
                           <span class="d-inline-block ml-2">Assinatura</span>
                        </v-list-item-title>
                     </v-list-item>
                     <v-list-item v-if="isClient" @click="resetTourAndRedirect">
                        <v-list-item-title @click="() => (menuStatus = false)">
                           <v-icon>mdi-restart</v-icon>
                           <span class="d-inline-block ml-2">Resetar Tour</span>
                        </v-list-item-title>
                     </v-list-item>

                     <VersionInfo />
                     <v-list-item @click="kickUser" v-if="isEnabledLogout">
                        <v-list-item-title @click="() => (menuStatus = false)">
                           <v-icon>mdi-logout</v-icon>
                           <span class="d-inline-block ml-2">Sair</span>
                        </v-list-item-title>
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </v-navigation-drawer>
         </div>
      </div>
      <!--  -->
      <!-- Pending Subscription -->
      <PendingSubscriptionModal />
   </div>
</template>

<script>
import anonymous from "@/shared/assets/user.jpeg";
import UserFigure from "@/shared/components/atomicDesign/atoms/UserFigure";
import PendingSubscriptionModal from "@/shared/components/atomicDesign/molecules/PendingSubscriptionModal.vue";
import WatchClientId from "@/shared/mixins/WatchClientId";
import "@/style/_topbar.scss";
import Loader from "@/utils/loader";
import { mapActions, mapGetters, mapState } from "vuex";
import VersionInfo from "@/VersionInfo";
import ChatButton from "@/shared/components/chat/ChatButton";
import NotificationBell from "@/components/notification/NotificationBell.vue";

export default {
   name: "Topbar",
   data() {
      return {
         drawer: false,
         group: null,
         breadcrumb: [],
         pageTitle: "",
         menuStatus: false,
         notificationLoader: new Loader(),
         clientExternal: this.isExternalClient
      };
   },
   components: {
      NotificationBell,
      PendingSubscriptionModal,
      UserFigure,
      VersionInfo,
      ChatButton
   },
   computed: {
      ...mapGetters([
         "budgetID",
         "myTasks",
         "organizationData",
         "role",
         "chatUserEnable",
         "userCanSeeToggleFeature"
      ]),
      ...mapState({
         persona: store => store.user.persona
      }),

      isEnabledLogout() {
         return !this.persona?.is_external || this.persona?.organization?.link_redirect;
      },
      planTypePlatform() {
         const PLATFORM_TYPE_SERVICE = 1;

         return this.persona?.current_subscription?.plan_code === PLATFORM_TYPE_SERVICE;
      },

      hasAccessToSignature() {
         if (this.planTypePlatform) {
            return false;
         }
         return this.role?.client;
      },
      avatar() {
         if (this.persona) {
            return this.persona.profile_picture || anonymous;
         } else {
            return anonymous;
         }
      },

      isPermissionInsight() {
         return this.userCanSeeToggleFeature("insight");
      },

      enableLogout() {
         return !this.persona?.is_external || this.persona?.organization?.link_redirect;
      },
      newTasks() {
         return this.myTasks.filter(task => task.status === "pending");
      },
      userData() {
         return this.persona || false;
      },
      plannerName() {
         const PERSONA = this.persona;

         if (PERSONA) {
            if (PERSONA.planner) {
               const PLANNER = PERSONA.planner;

               if (PLANNER) {
                  return PLANNER.user.first_name;
               }
            } else {
               throw {
                  text: "Client doesn't have a planner",
                  clientId: PERSONA.id,
                  clientEmail: PERSONA.email
               };
            }
         }
         return "Seu planejador";
      },
      taskIcon() {
         let taskIcon = "mdi-table-plus";

         return task => {
            task = task || null;

            if (task) {
               switch (task.type) {
                  case "import":
                     taskIcon = "mdi-table-plus";
                     break;
                  case "categorizing":
                     taskIcon = "mdi-table-edit";
                     break;
                  case "budget-planning":
                     taskIcon = "mdi-android-studio";
                     break;
                  case "notification":
                     taskIcon = "mdi-email-send-outline";
                     break;
                  default:
                     taskIcon = "mdi-table-plus";
               }
            }
            return taskIcon;
         };
      },
      taskType() {
         let taskName = "não definida";

         return task => {
            task = task || null;

            if (task) {
               switch (task.type) {
                  case "import":
                     taskName = "tarefa de importação";
                     break;
                  case "categorizing":
                     taskName = "tarefa de categorização";
                     break;
                  case "budget-planning":
                     taskName = "tarefa de planejamento";
                     break;
                  default:
                     taskName = "não definida";
               }
            }
            return taskName;
         };
      },
      userFullName() {
         if (this.persona) {
            let persona = this.persona;

            return `${persona.first_name} ${persona.last_name}`;
         } else {
            return "";
         }
      },

      inDashboard() {
         return this.$route.name === "dashboard";
      },

      clientIdQuery() {
         return this.$route.query.clientId;
      },
      isClient() {
         return this.role.client;
      },
      isMobile() {
         return window.innerWidth <= 1360;
      }
   },
   methods: {
      ...mapActions(["getMyTasks", "changeTaskStatus", "getBudgetTask", "kickUser", "resetTour"]),

      resetTourAndRedirect() {
         this.resetTour()
            .then(() => {
               this.$router.push({ name: "dashboard" });
            })
            .catch(() => {
               this.$toast.error("Não foi possível resetar o tour.");
            });
      },

      sendTo(pageName, params, query) {
         this.$router
            .push({
               name: pageName,
               ...(!!params && {
                  params: { ...params }
               }),
               ...(!!query && {
                  query: { ...query }
               })
            })
            .catch(() => {});
      },
      openLink() {
         window.open("https://portal.iniciador.com.br/", "_blank");
      },

      gotoTask(task) {
         task = task || null;

         if (task) {
            switch (task.type) {
               case "import":
                  this.sendTo("cashRegister", {
                     task: "importacao"
                  });
                  break;
               case "categorizing":
                  this.sendTo("categorizingTask", null, {
                     task: task.id
                  });
                  break;
               case "budget-planning":
                  this.getBudgetTask(task.id).then(() => {
                     this.sendTo("planejamento", null, {
                        task: task.id,
                        client: this.persona.id,
                        budget: this.budgetID
                     });
                  });
                  break;
               case "notification":
                  this.sendTo("tarefas", null, {
                     avisos: true,
                     client: this.persona.id
                  });
                  break;
            }
         } else {
            throw "task not defined, could not follow an unkown task";
         }
      },
      ignoreTask(task) {
         this.loadNotification();

         this.changeTaskStatus({
            taskID: task.id,
            status: "rejected"
         })
            .then(() => this.init())
            .finally(() => this.initNotifications())
            .catch(e => {
               throw Error(e);
            });
      },
      init() {
         this.updateBreadcrumb();
         this.updatePageTitle();
         this.initNotifications();
      },
      initNotifications() {
         if (!this.role.client) return;

         this.loadNotification();
         this.getMyTasks()
            .then(() => this.loadNotification("done"))
            .catch(() => this.loadNotification("done"));
      },
      isNewTask(task) {
         return task.status === "pending";
      },
      loadNotification(set) {
         this.notificationLoader.setter(set);
      },
      updateBreadcrumb() {
         const PLANNER_VIEW = this.role.planner;

         if (!PLANNER_VIEW) {
            this.breadcrumb = this.$route.meta.breadcrumb;
         }
      },
      updatePageTitle() {
         const BREADCRUMBS = this.$route.meta.breadcrumb;

         if (BREADCRUMBS) {
            const PAGE = BREADCRUMBS[BREADCRUMBS.length - 1];

            this.pageTitle = PAGE.name;
         }
      }
   },
   mixins: [WatchClientId],
   mounted() {
      this.clientExternal = this.persona?.is_external;
      this.init();
   },
   watch: {
      $route() {
         this.updateBreadcrumb();
         this.updatePageTitle();
         this.initNotifications();
      },
      role() {
         this.initNotifications();
      },
      group() {
         this.drawer = false;
      }
   }
};
</script>

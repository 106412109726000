import { Requests } from "@/services/requests";

const ENDPOINTS = {
   FIREBASE: "firebase",
   NOTIFICATION: "notifications"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   REMOVIDO_SUCCESS: "Removido com sucesso",
   MARK_SUCCESS: "Marcado como lido",
   GET_ALL_SUCCESS: "Retornados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class Notify {
   constructor(request) {
      this.request = request;
      this.moduleName = "Notify";
      this.routes = {
         add: {
            post: this.addToken.bind(this)
         },
         list: {
            get: this.listNotifications.bind(this)
         },
         mark: {
            patch: this.markNotificationAsRead.bind(this)
         },
         remove: {
            delete: this.removeToken.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   async addToken(token) {
      return this.makeRequest({
         url: `${ENDPOINTS.FIREBASE}/fcm/add-token`,
         method: "POST",
         body: { token: token.token },
         expectedStatus: {
            200: MSG.MARK_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async listNotifications() {
      return this.makeRequest({
         url: `${ENDPOINTS.NOTIFICATION}/`,
         method: "GET",
         expectedStatus: {
            200: MSG.GET_ALL_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async markNotificationAsRead(id_notification) {
      return this.makeRequest({
         url: `${ENDPOINTS.NOTIFICATION}/${id_notification}/mark/`,
         method: "PATCH",
         body: { read: true },
         expectedStatus: {
            200: MSG.MARK_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async removeToken(token) {
      return this.makeRequest({
         url: `${ENDPOINTS.FIREBASE}/fcm/remove-token`,
         method: "DELETE",
         body: { token: token.token },
         expectedStatus: {
            200: MSG.REMOVIDO_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}

<template>
   <div></div>
</template>

<script>
import { mapActions } from "vuex";
import { getFCMToken } from "@/firebase";

export default {
   name: "NotificationManager",
   methods: {
      ...mapActions(["addTokenFcm"]),

      async requestPermission() {
         const permission = await Notification.requestPermission();
         if (permission === "granted") {
            await this.getToken();
         }
      },

      async getToken() {
         const currentToken = await getFCMToken(process.env.VUE_APP_FIREBASE_VAPID_KEY);

         const storedToken = sessionStorage.getItem("fcmToken");
         if (storedToken !== currentToken) {
            sessionStorage.setItem("fcmToken", currentToken);
            await this.sendTokenToBackend(currentToken);
         }
      },

      async sendTokenToBackend(token) {
         await this.addTokenFcm({ token });
      }
   },

   async mounted() {
      if (!process.env.VUE_APP_FIREBASE_VAPID_KEY) {
         return;
      }
      await this.requestPermission();
   }
};
</script>

import { Mod } from "@/services/api";

export default {
   state: {
      notifications: []
   },
   mutations: {
      SET_NOTIFICATIONS(state, notifications) {
         state.notifications = notifications;
      }
   },
   actions: {
      // Removido _commit já que não está sendo usado
      async addTokenFcm(_context, { token }) {
         try {
            // Envie o token em um formato simples
            const data = {
               token: token
            };
            return await Mod.find("Notify", "add").post(data);
         } catch (e) {
            throw e;
         }
      },
      async listNotifications({ commit }) {
         try {
            const notifications = await Mod.find("Notify", "list").get();
            commit("SET_NOTIFICATIONS", notifications.data.data);
            return notifications.data.data;
         } catch (e) {
            throw new Error(e);
         }
      },
      // Removido _commit já que não está sendo usado
      async markNotificationAsRead(_context, { notificationId }) {
         try {
            await Mod.find("Notify", "mark").patch(notificationId);
            return true;
         } catch (e) {
            throw new Error(e);
         }
      },

      async removeToken() {
         const fcmToken = sessionStorage.getItem("fcmToken");
         if (fcmToken) {
            try {
               const data = {
                  token: fcmToken
               };
               await Mod.find("Notify", "remove").delete(data);
               sessionStorage.removeItem("fcmToken");
            } catch (e) {
               throw e;
            }
         }
      },

      showNewNotification({ commit }, notification) {
         commit("ADD_NOTIFICATION", notification);
      }
   }
};

import { Requests } from "@/services/requests";

const ENDPOINTS = {
   BASE: "ia/insights/"
};

const MSG = {
   CREATE_SUCCESS: "Criado com sucesso",
   MARK_SUCCESS: "Marcado como lido",
   GET_ALL_SUCCESS: "Retornados com sucesso",
   ERROR_DEFAULT: "Erro ao fazer a requisição"
};
export default class Insights {
   constructor(request) {
      this.request = request;
      this.moduleName = "Insights";
      this.routes = {
         list: {
            get: this.listInsights.bind(this)
         },
         mark: {
            put: this.markAsRead.bind(this)
         }
      };
   }
   async makeRequest({ url, method, body, headers, expectedStatus }) {
      return Requests[method]({ url, body, headers }, expectedStatus);
   }

   /**
    * @param {String} [clientId] (opcional) ID do client para filtrar
    */
   async listInsights(clientId = null) {
      let finalUrl = ENDPOINTS.BASE;
      if (clientId) {
         finalUrl += `?clientId=${clientId}`;
      }

      return this.makeRequest({
         url: finalUrl,
         method: "GET",
         expectedStatus: {
            200: MSG.GET_ALL_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }

   async markAsRead(params) {
      let finalUrl = `${ENDPOINTS.BASE}${params.insightId}/mark_read/`;
      if (params.clientId) {
         finalUrl += `?clientId=${params.clientId}`;
      }

      return this.makeRequest({
         url: finalUrl,
         method: "PATCH",
         expectedStatus: {
            200: MSG.MARK_SUCCESS,
            default: MSG.ERROR_DEFAULT
         }
      });
   }
}

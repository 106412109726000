<template>
   <div>
      <!-- Botão com Sino + Badge -->
      <v-menu offset-y transition="slide-y-transition" close-on-content-click>
         <!-- Slot que abre o menu ao clicar no ícone -->
         <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
               <!-- Badge mostrando quantidade de não lidas -->
               <v-badge :value="unreadCount > 0 ? unreadCount : ''" color="error" overlap>
                  <span slot="badge" v-if="unreadCount > 0">{{ unreadCount }}</span>
                  <v-icon>mdi-bell</v-icon>
               </v-badge>
            </v-btn>
         </template>

         <!-- Lista de notificações dentro do menu -->
         <v-list style="min-width: 300px; max-height: 400px; overflow-y: auto;">
            <div v-if="notifications.length === 0" class="pa-4 text-center">
               <p class="text-subtitle-2">Nenhuma notificação</p>
            </div>

            <!-- Loop pelas notificações -->
            <v-list-item
               v-for="notification in notifications"
               :key="notification.id"
               @click="onNotificationClick(notification)"
            >
               <v-list-item-content>
                  <!-- Título: fica em negrito se não lida -->
                  <v-list-item-title :class="{ 'font-weight-bold': !notification.read }">
                     {{ notification.title }}
                  </v-list-item-title>

                  <!-- Corpo da notificação (opcional) -->
                  <v-list-item-subtitle>
                     {{ notification.body }}
                  </v-list-item-subtitle>

                  <!-- Timestamp formatado -->
                  <v-list-item-subtitle>
                     {{ formatDate(notification.timestamp) }}
                  </v-list-item-subtitle>
               </v-list-item-content>

               <!-- Botão pra marcar como lida, se ainda não estiver lida -->
               <v-list-item-action>
                  <v-btn
                     small
                     text
                     color="primary"
                     @click.stop="markAsRead(notification)"
                     :disabled="notification.read || notification.loading"
                  >
                     <v-icon left v-if="notification.loading">mdi-loading</v-icon>
                     {{ notification.read ? "Lida" : "Marcar como lida" }}
                  </v-btn>
               </v-list-item-action>
            </v-list-item>
         </v-list>
      </v-menu>

      <v-dialog v-model="showDetailModal" max-width="600px">
         <v-card v-if="selectedNotification">
            <v-card-title class="headline">
               {{ selectedNotification.title }}
            </v-card-title>

            <v-card-subtitle>
               {{ formatDate(selectedNotification.timestamp) }}
            </v-card-subtitle>

            <v-card-text>
               <!-- Corpo principal da notificação -->
               <div class="mb-4">
                  {{ selectedNotification.body }}
               </div>

               <v-divider class="my-4"></v-divider>

               <!-- Detalhes específicos do data -->
               <div v-if="selectedNotification.data">
                  <v-row>
                     <v-col cols="6">
                        <strong>Cliente:</strong>
                        <div>{{ selectedNotification.data.client_name || "Cliente Local" }}</div>
                     </v-col>

                     <v-col cols="6">
                        <strong>ID do Insight:</strong>
                        <div>{{ selectedNotification.data.insight_id }}</div>
                     </v-col>

                     <v-col cols="6">
                        <strong>Data de Geração:</strong>
                        <div>{{ selectedNotification.data.generated_at }}</div>
                     </v-col>

                     <v-col cols="12" v-if="selectedNotification.data.additional_info">
                        <strong>Informações Adicionais:</strong>
                        <div>{{ selectedNotification.data.additional_info }}</div>
                     </v-col>
                  </v-row>
               </div>
            </v-card-text>

            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="primary" text @click="showDetailModal = false">
                  Fechar
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
   name: "NotificationBell",

   data() {
      return {
         showDetailModal: false,
         selectedNotification: null
      };
   },

   computed: {
      ...mapState({
         notifications: store => store.Notify.notifications
      }),
      /* Retorna o número de notificações não lidas */
      unreadCount() {
         return this.notifications.filter(n => !n.read).length;
      }
   },

   methods: {
      ...mapActions(["listNotifications", "markNotificationAsRead"]),

      /* Busca as notificações do backend */
      async fetchNotifications() {
         try {
            await this.listNotifications();
         } catch (error) {
            console.error("Erro ao buscar notificações:", error); // eslint-disable-line no-console
         }
      },

      handleVisibilityChange() {
         if (!document.hidden) {
            this.fetchNotifications();
         }
      },

      /* Ao clicar numa notificação */
      onNotificationClick(notification) {
         this.markAsRead(notification);
         this.showDetail(notification);
      },

      showDetail(notification) {
         this.selectedNotification = notification;
         this.showDetailModal = true;
      },

      /* Marca como lida, chamando a action no Vuex */
      async markAsRead(notification) {
         try {
            notification.loading = true;
            await this.markNotificationAsRead({ notificationId: notification.id });
            notification.read = true;
         } catch (error) {
            console.error("Erro ao marcar notificação como lida:", error); // eslint-disable-line no-console
         } finally {
            notification.loading = false;
         }
      },

      /* Formata a data/hora */
      formatDate(timestamp) {
         const date = new Date(timestamp);
         return date.toLocaleString();
      }
   },

   /* Ao montar, busca as notificações */
   mounted() {
      this.fetchNotifications();
      document.addEventListener("visibilitychange", this.handleVisibilityChange);

      // 2. Ouvir mensagens do Service Worker
      if ("serviceWorker" in navigator) {
         navigator.serviceWorker.addEventListener("message", event => {
            switch (event.data.type) {
               case "NEW_NOTIFICATION":
               case "NOTIFICATION_CLICK":
                  this.fetchNotifications();
                  break;
            }
         });
      }
   },

   beforeDestroy() {
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
   }
};
</script>

<style scoped>
.font-weight-bold {
   font-weight: bold;
}
</style>
